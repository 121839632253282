export const environment = {
  production: true,
  instance: 'https://login.microsoftonline.com/',
  clientId: 'a5341d23-1ecf-48e9-9fc6-55fae87ed91e',
  tenantId: 'common',
  authority: 'https://login.microsoftonline.com/common',
  ishtarFunctions: 'https://functions.dev.ishtar365.com',
  ishtarFunctionsKey:
    'Rl1x2qHDmM4-SvoNyzQDb-SRDTv-qXStKozJkQlMzF9lAzFu6Qk4VA==',
  ishtarFunctionsScope:
    'api://a5341d23-1ecf-48e9-9fc6-55fae87ed91e/user_impersonation',
  ishtar365: 'https://portal.dev.ishtar365.com',
  appIdentifier: 'tasks.dev',
  cdnAppIconUrl:
    'https://ishtar365storage.blob.core.windows.net/icons/Tasks_color.svg',
  lastRouteKey: 'ISHTARTASKLASTROUTEKEY',
  ishtar_word_icon: 'https://ishtar.azureedge.net/user/assets/word_48x1.svg',
  ishtar_powerpoint_icon:
    'https://ishtar.azureedge.net/user/assets/powerpoint_48x1.svg',
  ishtar_excel_icon: 'https://ishtar.azureedge.net/user/assets/excel_48x1.svg',
  ishtar_pdf_icon: 'https://ishtar.azureedge.net/user/assets/pdf.svg',
  ishtar_picture_icon: 'https://ishtar.azureedge.net/user/assets/jpg-png.svg',
  ishtar_movie_icon: 'https://ishtar.azureedge.net/user/assets/Movie.svg',
  ishtar_email_icon: 'https://ishtar.azureedge.net/user/assets/email.svg',
  ishtar_default_icon:
    'https://ishtar.azureedge.net/user/assets/Default_file.svg',
};
