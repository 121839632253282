import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { Skill } from '../domain/models/skill.model';
import { ActionType } from '../domain/models/task/decision/action-type.model';
import { LogType } from '../domain/models/task/log-type.model';
import {
  ApprovalType,
  Country,
  Day,
  DependencyType,
  Frequency,
  MetadataParam,
  Language,
  Month,
  Ranking,
  RegistrationType,
  TaskType,
  ResourceUser,
  ResourceThing,
} from 'processdelight-angular-components';
import { TypesContract } from '../domain/contracts/typesContract';

@Injectable({ providedIn: 'root' })
export class TypesApiService {
  apiBase = `${location.origin}/web/type`;

  constructor(private httpClient: HttpClient) {}

  /////////////////////////////////////////////////// GET ///////////////////////////////////////////////////

  public getStartUpData(): Observable<TypesContract> {
    const url = `${this.apiBase}/startup`;
    return this.httpClient.get<TypesContract>(url).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }
}
