import { Action, createReducer, on } from '@ngrx/store';
import { DMSItem, Status, Task } from 'processdelight-angular-components';
import { AppState } from 'src/app/app.reducer';
import { Kanban } from '../../domain/models/task/kanban.model';
import { TemplateTask } from '../../domain/models/task/template-task.model';
import {
  addLogsResolved,
  addStatusesResolved,
  addTaskResolved,
  createTaskDocumentsResolved,
  deleteStatusResolved,
  deleteTaskResolved,
  getAllTasksResolved,
  getKanbanResolved,
  getStatusesResolved,
  getTaskDocumentsResolved,
  getTemplateTasksResolved,
  removeTaskDocumentsResolved,
  updateStatusesResolved,
  updateTaskResolved,
} from './task.actions';

export const featureSlice = 'ishtarTask';

export interface State {
  tasks: Task[];
  allTasks: Task[];
  templateTasks: TemplateTask[];
  task?: Task;
  id?: string;
  pageNumber: number;
  totalRecordCount: number;
  taskDocuments: DMSItem[] | undefined;
  statuses: Status[];
  kanbanBoard?: Kanban[];
}
const defaultState: State = {
  tasks: [],
  allTasks: [],
  templateTasks: [],
  task: undefined,
  id: undefined,
  pageNumber: 0,
  totalRecordCount: 0,
  taskDocuments: undefined,
  statuses: [],
  kanbanBoard: undefined,
};

export const initialState: State = defaultState;

const taskReducer = createReducer(
  initialState,
  on(getAllTasksResolved, (state, { tasks }) => ({
    ...state,
    allTasks: tasks,
  })),
  on(getTemplateTasksResolved, (state, { tasks }) => ({
    ...state,
    templateTasks: tasks,
  })),
  on(addTaskResolved, (state, { addedTask }) => ({
    ...state,
    tasks: [...state.tasks, addedTask],
    allTasks: state.allTasks.concat([addedTask]),
  })),
  // on(addSubtaskResolved, (state, { addedTask }) => ({
  //   ...state,
  //   tasks: [addedTask].concat([...state.tasks]),
  // })),
  on(deleteTaskResolved, (state, { id }) => ({
    ...state,
    tasks: state.tasks.filter((t) => t.isDeleted || t.id != id),
    allTasks: state.allTasks.filter((t) => t.isDeleted || t.id != id),
  })),
  on(updateTaskResolved, (state, task) => ({
    ...state,
    tasks: state.tasks.map((t) => (t.id == task.id ? task : t)),
    allTasks: state.allTasks.map((t) => (t.id == task.id ? task : t)),
    kanbanBoard:
      state.kanbanBoard?.map((t) =>
        t.id == task.id
          ? new Kanban({
              id: task.id,
              name: task.title,
              statusId: task.statusId,
            })
          : t
      ) || undefined,
  })),
  on(addLogsResolved, (state, { logs }) => ({
    ...state,
    tasks: state.tasks.map((t) => {
      const taskLogs = logs.filter((l) => l.taskId === t.id);
      return taskLogs.length > 0 ? new Task({ ...t, logs: taskLogs }) : t;
    }),
  })),
  on(createTaskDocumentsResolved, (state, { contract }) => ({
    ...state,
    taskDocuments: state.taskDocuments
      ? state.taskDocuments.concat([...(contract.createdItems ?? [])])
      : [...(contract.createdItems ?? [])],
  })),
  on(removeTaskDocumentsResolved, (state, { taskId, dmsAppItemIds }) => ({
    ...state,
    tasks: state.tasks.map((t) => {
      if (t.id !== taskId) return t;
      return new Task({
        ...t,
        documents: t.documents?.filter((d) =>
          dmsAppItemIds.some((id) => d.appItems?.some((i) => i.id === id))
        ),
      });
    }),
  })),
  on(deleteStatusResolved, (state, { id }) => {
    return {
      ...state,
      statuses: state.statuses?.filter((s) => s.id !== id),
    };
  }),
  on(updateStatusesResolved, (state, { updatedStatuses }) => ({
    ...state,
    statuses: state.statuses?.map(
      (s) => updatedStatuses.find((u) => s.id === u.id) ?? s
    ),
  })),
  on(addStatusesResolved, (state, { addedStatuses }) => ({
    ...state,
    statuses: addedStatuses.concat([...(state.statuses ?? [])]),
  })),
  on(getKanbanResolved, (state, { tasks }) => ({
    ...state,
    kanbanBoard: tasks,
  })),
  on(getTaskDocumentsResolved, (state, { taskDocuments }) => ({
    ...state,
    taskDocuments,
  })),
  on(getStatusesResolved, (state, { statuses }) => ({
    ...state,
    statuses,
  }))
);

export function Reducer(state: State | undefined, action: Action) {
  return taskReducer(state, action);
}

export const taskState = (state: AppState) => state.taskFeature.ishtarTask;
