import {
  ApprovalType,
  Application,
  ChannelType,
  ContextMenuAction,
  Country,
  Day,
  DependencyType,
  Frequency,
  GroupUser,
  MetadataParam,
  Language,
  Month,
  PropertyRight,
  PropertyType,
  Ranking,
  RegistrationType,
  Task,
  TaskType,
  TriggerType,
  ResourceThing,
  ResourceUser,
  Status,
  StatusType,
} from 'processdelight-angular-components';
import { BehaviorSubject } from 'rxjs';
import { ProjectHolder } from '../domain/models/task/project.model';
import { LogType } from '../domain/models/task/log-type.model';
import { License } from '../domain/models/user/license.model';
import { LicenseInfo } from '../domain/models/user/license-info.model';
import { ActionType } from '../domain/models/task/decision/action-type.model';
import { Skill } from '../domain/models/skill.model';

export const search$ = new BehaviorSubject<string>('');
export const licenseInfo$ = new BehaviorSubject<LicenseInfo | undefined>(
  undefined
);
export const license$ = new BehaviorSubject<License | undefined>(undefined);
export const users$ = new BehaviorSubject<GroupUser[] | undefined>(undefined);
export const groups$ = new BehaviorSubject<GroupUser[] | undefined>(undefined);
export const projects$ = new BehaviorSubject<ProjectHolder[] | undefined>(
  undefined
);
export const skills$ = new BehaviorSubject<Skill[] | undefined>(undefined);
export const taskTypes$ = new BehaviorSubject<TaskType[] | undefined>(
  undefined
);
export const registrationTypes$ = new BehaviorSubject<
  RegistrationType[] | undefined
>(undefined);
export const actionTypes$ = new BehaviorSubject<ActionType[] | undefined>(
  undefined
);
export const allTasks$ = new BehaviorSubject<Task[] | undefined>(undefined);
export const statuses$ = new BehaviorSubject<Status[] | undefined>(undefined);

export const dependencyTypes$ = new BehaviorSubject<
  DependencyType[] | undefined
>(undefined);
export const logTypes$ = new BehaviorSubject<LogType[] | undefined>(undefined);
export const approvalTypes$ = new BehaviorSubject<ApprovalType[] | undefined>(
  undefined
);
export const frequencies$ = new BehaviorSubject<Frequency[] | undefined>(
  undefined
);
export const days$ = new BehaviorSubject<Day[] | undefined>(undefined);
export const months$ = new BehaviorSubject<Month[] | undefined>(undefined);
export const rankings$ = new BehaviorSubject<Ranking[] | undefined>(undefined);
export const statusTypes$ = new BehaviorSubject<StatusType[] | undefined>(
  undefined
);
export const metadataParameters$ = new BehaviorSubject<
MetadataParam[] | undefined
>(undefined);
export const resourceThings$ = new BehaviorSubject<ResourceThing[] | undefined>(
  undefined
);
export const resourceUsers$ = new BehaviorSubject<ResourceUser[] | undefined>(
  undefined
);
export const countries$ = new BehaviorSubject<Country[] | undefined>(undefined);
export const propertyTypes$ = new BehaviorSubject<PropertyType[]>([]);
export const propertyRights$ = new BehaviorSubject<PropertyRight[]>([]);
export const currentApplication$ = new BehaviorSubject<Application | undefined>(
  undefined
);
export const varlanguages$ = new BehaviorSubject<Language[] | undefined>(
  undefined
);
export const navActions$ = new BehaviorSubject<ContextMenuAction<unknown>[]>(
  []
);
export const action$ = new BehaviorSubject<any>({});
