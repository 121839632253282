import { createAction, props } from '@ngrx/store';
import { ActionBase } from '../action-base.interface';
import { Kanban } from '../../domain/models/task/kanban.model';
import {
  DMSItem,
  EntityObject,
  Log,
  Metadata,
  Task,
  Status,
} from 'processdelight-angular-components';
import { TemplateTask } from '../../domain/models/task/template-task.model';

export const getAllTasks = createAction(
  '[Task] Get All Tasks',
  props<{
    callback?: (tasks: Task[]) => void;
    errorCallback?: (error: any) => void;
  }>()
);

export const getAllTasksResolved = createAction(
  '[Task] Get All Tasks Resolved',
  props<{
    tasks: Task[];
  }>()
);

export const getTemplateTasks = createAction(
  '[Task] Get Template Tasks',
  props<{
    callback?: () => void;
    errorCallback?: (error: any) => void;
  }>()
);

export const getTemplateTasksResolved = createAction(
  '[Task] Get Template Tasks Resolved',
  props<{
    tasks: TemplateTask[];
  }>()
);

export const getTaskById = createAction(
  '[Task] Get Task by id',
  props<
    {
      id: string;
      errorCallback?: (error: any) => void;
    } & ActionBase<{ task: Task }>
  >()
);

export const getTaskResolved = createAction(
  '[Task] Get Task Resolved',
  props<{
    task: Task;
  }>()
);

export const addTask = createAction(
  '[Task] Add Task',
  props<
    {
      isTeamsMeeting: boolean;
      task: Task;
      errorCallback?: (error: any) => void;
    } & ActionBase<Task>
  >()
);

export const addTaskResolved = createAction(
  '[Task] Add Task Resolved',
  props<{
    addedTask: Task;
  }>()
);

export const updateTask = createAction(
  '[Task] Update Task',
  props<
    {
      task: Task;
      errorCallback?: (error: any) => void;
    } & ActionBase<Task>
  >()
);
export const updateTaskStatus = createAction(
  '[Task] Update Task status',
  props<
    {
      taskId: string;
      statusId: string;
    } & ActionBase<{ tasks: Task }>
  >()
);

export const updateTaskResolved = createAction(
  '[Task] Update Task Resolved',
  props<Task>()
);

export const deleteTask = createAction(
  '[Task] Delete Task',
  props<
    {
      id: string;
      errorCallback?: (error: any) => void;
    } & ActionBase<string>
  >()
);

export const deleteTaskResolved = createAction(
  '[Task] Delete Task Resolved',
  props<{
    id: string;
  }>()
);

export const addLogs = createAction(
  '[Task] Add Logs',
  props<{ logs: Log[] } & ActionBase<Log[]>>()
);

export const addLogsResolved = createAction(
  '[Task] Add Logs Resolved',
  props<{ logs: Log[] }>()
);

export const getTaskDocuments = createAction(
  '[Task] Get Task Documents',
  props<{
    taskId: string;
    callback?: (taskDocuments: DMSItem[]) => void;
    errorCallback?: (error: any) => void;
  }>()
);

export const getTaskDocumentsResolved = createAction(
  '[Task] Get Task Documents Resolved',
  props<{
    taskDocuments: DMSItem[];
  }>()
);

export const createTaskDocuments = createAction(
  '[Task] Create Task Documents',
  props<
    {
      documents: {
        taskId: string;
        filename: string;
        fileLocation: string;
        sharepointId: string;
      }[];
    } & ActionBase<{
      createdItems: DMSItem[];
      updatedMetadata: Metadata[];
    }>
  >()
);

export const createTaskDocumentsResolved = createAction(
  '[Task] Create Task Documents Resolved',
  props<{
    contract: {
      createdItems: DMSItem[];
      updatedMetadata: Metadata[];
    };
  }>()
);

export const removeTaskDocuments = createAction(
  '[Task] Remove documents from a Task',
  props<
    {
      taskId: string;
      dmsAppItemIds: string[];
    } & ActionBase<string[]>
  >()
);

export const removeTaskDocumentsResolved = createAction(
  '[Task] Remove document from a Task Resolved',
  props<{
    taskId: string;
    dmsAppItemIds: string[];
  }>()
);

export const getSubTasks = createAction(
  '[Task] Get Subtasks',
  props<{
    taskId: string;
    callback?: (subTasks: EntityObject<Task>[]) => void;
    errorCallback?: (error: any) => void;
  }>()
);

export const getSubTasksResolved = createAction(
  '[Task] Get Subtasks Resolved',
  props<{
    subTasks: EntityObject<Task>[];
  }>()
);

export const deleteStatus = createAction(
  '[Status] Delete Status',
  props<
    {
      id: string;
    } & ActionBase<string>
  >()
);

export const deleteStatusResolved = createAction(
  '[Status] Delete Status Resolved',
  props<{
    id: string;
  }>()
);

export const getStatuses = createAction(
  '[Statuses] Get statuses',
  props<ActionBase<Status[]>>()
);

export const getStatusesResolved = createAction(
  '[Statuses] Get statuses resolved',
  props<{ statuses: Status[] }>()
);

export const updateStatuses = createAction(
  '[Statuses] Update statuses',
  props<{ statuses: Status[] } & ActionBase<Status[]>>()
);

export const updateStatusesResolved = createAction(
  '[Statuses] Update statuses resolved',
  props<{ updatedStatuses: Status[] }>()
);

export const addStatuses = createAction(
  '[Statuses] Add statuses',
  props<{ statuses: Status[] } & ActionBase<Status[]>>()
);

export const addStatusesResolved = createAction(
  '[Statuses] Add statuses resolved',
  props<{ addedStatuses: Status[] }>()
);

export const getKanban = createAction(
  '[Kanban] Get kanban',
  props<
    {
      assignedToMe: boolean;
    } & ActionBase<{
      tasks: Kanban[];
    }>
  >()
);

export const getKanbanResolved = createAction(
  '[Kanban] Get kanban Resolved',
  props<{
    tasks: Kanban[];
  }>()
);
