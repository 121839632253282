import { DateTime } from 'luxon';
import { license$ } from '../data/data.observables';
import { Task } from 'processdelight-angular-components';

export function camelcaseKeys(obj: any): any {
  if (Array.isArray(obj)) return [...obj.map((o) => camelcaseKeys(o))];
  else if (obj instanceof Object)
    return Object.entries(obj).reduce(
      (acc, e) => ({
        ...acc,
        [e[0].charAt(0).toLowerCase() + e[0].slice(1)]: camelcaseKeys(e[1]),
      }),
      {}
    );
  else return obj;
}

export function capitalizeKeys(obj: any, ...ignoredProperties: string[]): any {
  const ignoredPropertiesLower = ignoredProperties.map((p) => p.toLowerCase());
  if (Array.isArray(obj))
    return [...obj.map((o) => capitalizeKeys(o, ...ignoredProperties))];
  else if (obj instanceof Object)
    return Object.entries(obj).reduce(
      (acc, e) => ({
        ...acc,
        [e[0].charAt(0).toUpperCase() + e[0].slice(1)]:
          ignoredPropertiesLower.includes(e[0].toLowerCase())
            ? e[1]
            : capitalizeKeys(e[1], ...ignoredProperties),
      }),
      {}
    );
  else return obj;
}

export function combineDateAndTime(date: Date | DateTime, timestr: string) {
  if (!date || !timestr) return undefined;
  let startTimeArr = timestr.split(':').map((t) => Number(t));
  if (startTimeArr.some((r) => isNaN(r))) startTimeArr = [0, 0];
  const startTime = {
    hour: startTimeArr[0],
    minute: startTimeArr[1],
  };
  const isLuxonDateTime = !(date instanceof Date);
  const combinedDateTime = new Date(
    isLuxonDateTime ? date.year : date.getFullYear(),
    isLuxonDateTime ? date.month - 1 : date.getMonth(),
    isLuxonDateTime ? date.day : date.getDate(),
    startTime.hour,
    startTime.minute
  );
  if (isNaN(combinedDateTime.getTime())) return undefined;
  return combinedDateTime;
}

export function isTaskOwner(task: Task, currentUserId: string): boolean {
  if (license$.value?.isAdmin) return true;
  return task?.createdBy?.id === currentUserId || !task?.id;
}
